<template>
	<div data-component="title-page">
		<div data-element="wrapper">
			<h1
				data-element="heading"
			>
				{{ this.getHeading }}
			</h1>
			<div
				v-if="getText"
				data-element="text"
				v-html="getText"
			/>
		</div>
		<div class="spacer" />
		<activity-buttons
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>
	import ActivityButtons     from '@/components/activities/view/ActivityButtons';

	export default {
		components: {
			ActivityButtons
		},
		props: {
			isPreview: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({}),
		computed: {
			getHeading () {
				return this.$store.getters['lesson/getActivityIntertitleHeading'];
			},
			getText () {
				return this.$store.getters['lesson/getActivityIntertitleText'];
			},

			getPreviousActivityRoute () {
				return this.$store.getters['lesson/getPreviousActivityRoute'];
			},
			getNextActivityRoute () {
				return this.$store.getters['lesson/getNextActivityRoute'];
			},
			getShowPreviousButton () {
				if (!this.getPreviousActivityRoute) {
					return false;
				}
				return true;
			},

			getShowNextButton () {
				if (!this.getNextActivityRoute) {
					return false;
				}
				return true;
			},

			getShowFinishButton () {
        if (this.getNextActivityRoute) {
          return false;
        }
        return true;
			},

			getTourStep () {

				return [
					(this.getShowPreviousButton ?
						{
							target: '[data-tour-id="goToPreviousActivity"]',
							content: 'Click here to go back and revisit the last activity',
							tourId: 'goToPreviousActivity'
						} : false
					),
					(this.getShowNextButton ?
						{
							target: '[data-tour-id="goToNextActivity"]',
							content: 'Click here to go to the next activity',
							tourId: 'goToNextActivity'
						} : false
					),
					(this.getShowFinishButton ?
						{
							target: '[data-tour-id="returnToCourse"]',
							content: 'Click here to go back to the list of courses',
							tourId: 'returnToCourse'
						} : false
					),
					(this.getActivityMediaComponent === 'PresentationViewer' ?
						{
							target: '[data-tour-id="presentationViewer"]',
							content: 'Use the arrow buttons to move presentation forward or back',
							tourId: 'presentationViewer',
							params: {
								placement: 'top',
								modifiers: [
									{
										name: 'flip',
										options: {
											fallbackPlacements: []
										}
									}
								]
							}
						} : false
					)
				].filter(Boolean);
			}
		},

		mounted () {
      if (!this.isPreview) {
        this.setActivityProgress({ progress: true });
      }

      this.$store.commit('ui/addTourSteps', {
        tourSteps: this.getTourStep
      });
		},

		methods: {
			setActivityProgress ({ progress }) {
				this.$store.dispatch('lesson/setActivityProgress', {
					progress
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

.spacer {
  height: 100%;
  width: 1px;
}

	[data-component='title-page'] {
		[data-element='wrapper'] {
			[data-element='heading'] {
				margin:0 0 rem(24);
        @include font(48px, $c-darkest-navy, 700, 52px);
				text-align:center;
			}
			::v-deep [data-element='text'] {
				font-size:rem(22);
				text-align:center;
				@include rich-text-content;
				@include rich-text-suits;
			}
		}
	}

</style>
