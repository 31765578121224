<template>
	<div
		class="bid-button"
		:class="{
			inactive: !getIsValid,
			[getType]: true,
			selected,
			[highlight]: true,
		}"
		:data-value="bid.value"
		@click="clickBid"
	>
		<V2Bid
			:bid="bid"
			:showBackgroundColour="true"
			:showLongPass="showLongPass"
		/>
	</div>
</template>

<script>

	import V2Bid         from '@/components/v2/diagrams/V2Bid';

	export default {
		components: {
			V2Bid
		},

		emits: ['select-bid'],
		props: {
			bid: {
				type: Object,
				required: true
			},

			showLongPass: {
				type: Boolean,
				default: false
			},

			selected: {
				type: Boolean,
				default: false
			},

			isCorrect: {
				type: [Boolean, null],
				default: null
			}
		},
		computed: {
			getIsValid () {
				return this.bid.isValid;
			},
			getType () {
				return this.bid.type;
			},
			highlight () {
        if (this.isCorrect === null) {
          return '';
        }
        return this.isCorrect ? 'correct' : 'incorrect';
			}
		},
		methods: {
			clickBid () {
        if (!this.getIsValid || this.isCorrect !== null) {
          return;
        }

        this.$emit('select-bid', this.bid);
			}
		}
	};

</script>

<style lang="scss" scoped>

	.bid-button {
		display:flex;
		margin:0;
		opacity:1;
		cursor:pointer;
		user-select:none;

    @include font(16px, $c-darkest-navy, 400, 20px);

		&.inactive {
			filter: brightness(80%);
			cursor: not-allowed;
		}

    &.selected {
      border: 2px solid $c-darkest-navy;
      z-index: 2;
    }

    &.selected.correct {
      border: 2px solid $c-green-l;
      z-index: 2;
    }

    &.selected.incorrect {
      border: 2px solid $c-red-l;
      z-index: 2;
    }

    &:hover:not(.inactive, .selected, .correct, .incorrect) {
      filter: brightness(120%);
      z-index: 2;
    }
	}

</style>
