<template>
	<div data-component="media">
		<component
			v-if="getMediaUrl"
			:is="getComponent"
			:src="getMediaUrl"
		/>
		<div data-element="presentation-tour-wrapper">
			<div data-element="presentation-tour" :data-tour-id="getTourId" />
		</div>
		<activity-buttons
			:isPreview="isPreview"
		/>
	</div>
</template>

<script>

	import PresentationViewer  from '@/components/uploads/PresentationViewer';
	import VideoViewer         from '@/components/uploads/VideoViewer';
	import ActivityButtons     from '@/components/activities/view/ActivityButtons';
	import { camelCase }       from 'change-case';

	export default {
		components: {
			PresentationViewer,
			VideoViewer,
			ActivityButtons
		},
		props: {
			isPreview: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			getComponent () {
				return this.$store.getters['lesson/getActivityMediaComponent'];
			},
			getMediaUrl () {
				return this.$store.getters['lesson/getActivityMediaUrl'];
			},
			getActivityComponent () {
				return this.$store.getters['lesson/getActivityComponent'];
			},
			getActivityMediaComponent () {
				return this.$store.getters['lesson/getActivityMediaComponent'];
			},

			getPreviousActivityRoute () {
				return this.$store.getters['lesson/getPreviousActivityRoute'];
			},
			getNextActivityRoute () {
				return this.$store.getters['lesson/getNextActivityRoute'];
			},
			getShowPreviousButton () {
				if (!this.getPreviousActivityRoute) {
					return false;
				}
				return true;
			},

			getShowNextButton () {
				if (!this.getNextActivityRoute) {
					return false;
				}
				return true;
			},

			getShowFinishButton () {
        if (this.getNextActivityRoute) {
          return false;
        }
        return true;
			},

			getTourStep () {

				return [
					(this.getShowPreviousButton ?
						{
							target: '[data-tour-id="goToPreviousActivity"]',
							content: 'Click here to go back and revisit the last activity',
							tourId: 'goToPreviousActivity'
						} : false
					),
					(this.getShowNextButton ?
						{
							target: '[data-tour-id="goToNextActivity"]',
							content: 'Click here to go to the next activity',
							tourId: 'goToNextActivity'
						} : false
					),
					(this.getShowFinishButton ?
						{
							target: '[data-tour-id="returnToCourse"]',
							content: 'Click here to go back to the list of courses',
							tourId: 'returnToCourse'
						} : false
					),
					(this.getActivityMediaComponent === 'PresentationViewer' ?
						{
							target: '[data-tour-id="presentationViewer"]',
							content: 'Use the arrow buttons to move presentation forward or back',
							tourId: 'presentationViewer',
							params: {
								placement: 'top',
								modifiers: [
									{
										name: 'flip',
										options: {
											fallbackPlacements: []
										}
									}
								]
							}
						} : false
					)
				].filter(Boolean);
			},

			getTourId () {
				if (!this.getActivityComponent) {
					return false;
				}
				if (this.getActivityComponent !== 'Media') {
					return false;
				}
				if (!this.getActivityMediaComponent) {
					return false;
				}
				return camelCase(this.getActivityMediaComponent);
			}
		},

		mounted () {
      if (!this.isPreview) {
        this.setActivityProgress({ progress: true });
      }

      this.$store.commit('ui/addTourSteps', {
        tourSteps: this.getTourStep
      });
		},

		methods: {
			setActivityProgress ({ progress }) {
				this.$store.dispatch('lesson/setActivityProgress', {
					progress
				});
			}
		}

	};

</script>

<style lang="scss" scoped>

	[data-component='media'] {
		[data-component='presentation-viewer'],
		[data-component='video-viewer'] {
			margin-bottom:rem(24);
		}
		[data-element='presentation-tour-wrapper'] {
			display:flex;
			justify-content:center;
			width:100%;
			height:0;
			margin-top:rem(-48);
			[data-element='presentation-tour'] {
			}

      width: 100%;
      max-width: 1200px;

      @media (max-width: 1279px) {
        padding-left: 20px;
        padding-right: 20px;
      }
		}
	}

</style>
